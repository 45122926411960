/* tslint:disable */
/* eslint-disable */
/* @relayHash 5eb4e325bd80a7ea4b8fa7e84195ad1a */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserHistoryDrawerAllCommentsPaginationQueryVariables = {
    userID: string;
    count: number;
    cursor?: unknown | null;
};
export type UserHistoryDrawerAllCommentsPaginationQueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerAllComments_user">;
    } | null;
};
export type UserHistoryDrawerAllCommentsPaginationQuery = {
    readonly response: UserHistoryDrawerAllCommentsPaginationQueryResponse;
    readonly variables: UserHistoryDrawerAllCommentsPaginationQueryVariables;
};



/*
query UserHistoryDrawerAllCommentsPaginationQuery(
  $userID: ID!
  $count: Int!
  $cursor: Cursor
) {
  user(id: $userID) {
    ...UserHistoryDrawerAllComments_user_1G22uz
    id
  }
}

fragment AutomatedActionsContainer_comment on Comment {
  revision {
    metadata {
      perspective {
        score
      }
      externalModeration {
        name
        analyzedAt
        result {
          status
          tags
          actions {
            reason
          }
        }
      }
    }
    id
  }
}

fragment CommentAuthorContainer_comment on Comment {
  author {
    id
    username
    status {
      suspension {
        active
      }
      premod {
        active
      }
      ban {
        active
      }
    }
  }
}

fragment CommentRevisionContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    id
  }
  revisionHistory {
    id
    body
    createdAt
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
  }
}

fragment DecisionDetailsContainer_comment on Comment {
  id
  statusHistory(first: 1) {
    edges {
      node {
        createdAt
        rejectionReason {
          code
          legalGrounds
          detailedExplanation
          customReason
        }
        id
      }
    }
  }
}

fragment FlagDetailsContainer_comment on Comment {
  flags {
    nodes {
      flagger {
        username
        id
      }
      reason
      additionalDetails
      id
    }
  }
  illegalContent {
    nodes {
      flagger {
        username
        id
      }
      additionalDetails
      reportID
      id
    }
  }
  revision {
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment LinkDetailsContainer_comment on Comment {
  id
  story {
    id
    url
  }
}

fragment MarkersContainer_comment on Comment {
  ...ModerateCardDetailsContainer_comment
  initialStatus
  status
  tags {
    code
  }
  author {
    premoderatedBecauseOfEmailAt
    id
  }
  revision {
    actionCounts {
      illegal {
        total
      }
      flag {
        reasons {
          COMMENT_REPORTED_SPAM
          COMMENT_REPORTED_OTHER
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_ABUSIVE
          COMMENT_REPORTED_BIO
          COMMENT_DETECTED_TOXIC
          COMMENT_DETECTED_SUSPECT_WORD
          COMMENT_DETECTED_SPAM
          COMMENT_DETECTED_REPEAT_POST
          COMMENT_DETECTED_RECENT_HISTORY
          COMMENT_DETECTED_NEW_COMMENTER
          COMMENT_DETECTED_LINKS
          COMMENT_DETECTED_BANNED_WORD
        }
      }
    }
    metadata {
      wordList {
        timedOut
      }
      externalModeration {
        name
        analyzedAt
        result {
          status
          tags
          actions {
            reason
          }
        }
      }
    }
    id
  }
}

fragment MediaContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
    id
  }
}

fragment ModerateCardContainer_comment on Comment {
  id
  site {
    id
    name
  }
  author {
    id
    email
    username
    status {
      current
      ban {
        active
        sites {
          id
          name
        }
      }
    }
    role
  }
  createdAt
  body
  rating
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_DETECTED_BANNED_WORD
          COMMENT_DETECTED_SUSPECT_WORD
        }
      }
    }
    metadata {
      wordList {
        bannedWords {
          value
          index
          length
        }
        suspectWords {
          value
          index
          length
        }
      }
    }
    id
  }
  tags {
    code
  }
  status
  editing {
    edited
  }
  parent {
    author {
      id
      username
    }
    id
  }
  canModerate
  story {
    id
    metadata {
      title
    }
    settings {
      mode
    }
    isArchived
    isArchiving
  }
  permalink
  deleted
  ...MarkersContainer_comment
  ...ModeratedByContainer_comment
  ...CommentAuthorContainer_comment
  ...MediaContainer_comment
}

fragment ModerateCardDetailsContainer_comment on Comment {
  id
  status
  tags {
    code
  }
  editing {
    edited
  }
  statusHistory(first: 1) {
    edges {
      node {
        rejectionReason {
          code
        }
        id
      }
    }
  }
  revision {
    actionCounts {
      illegal {
        total
      }
      flag {
        reasons {
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_ABUSIVE
          COMMENT_REPORTED_SPAM
          COMMENT_REPORTED_OTHER
          COMMENT_DETECTED_TOXIC
          COMMENT_DETECTED_SPAM
          COMMENT_REPORTED_BIO
        }
      }
      reaction {
        total
      }
    }
    metadata {
      perspective {
        score
      }
      externalModeration {
        name
        result {
          status
          tags
          actions {
            reason
          }
        }
      }
    }
    id
  }
  ...FlagDetailsContainer_comment
  ...CommentRevisionContainer_comment
  ...LinkDetailsContainer_comment
  ...AutomatedActionsContainer_comment
  ...DecisionDetailsContainer_comment
}

fragment ModeratedByContainer_comment on Comment {
  id
  status
  statusHistory(first: 1) {
    edges {
      node {
        status
        moderator {
          id
          username
        }
        id
      }
    }
  }
  revision {
    metadata {
      externalModeration {
        name
        result {
          status
        }
      }
    }
    id
  }
}

fragment UserHistoryDrawerAllComments_user_1G22uz on User {
  username
  email
  allComments(first: $count, after: $cursor) {
    edges {
      node {
        id
        ...ModerateCardContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "userID"
    } as any), v3 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
        } as any)
    ], v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any), v6 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any)
    ], v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v9 = [
        (v7 /*: any*/),
        (v8 /*: any*/)
    ], v10 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    } as any), v11 = [
        (v10 /*: any*/)
    ], v12 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
    } as any), v14 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
        } as any)
    ], v15 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        } as any),
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "index",
            "storageKey": null
        } as any),
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "length",
            "storageKey": null
        } as any)
    ], v16 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v17 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reason",
        "storageKey": null
    } as any), v18 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any), v19 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v20 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v21 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v22 = [
        (v19 /*: any*/)
    ], v23 = ({
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "media",
        "plural": false,
        "selections": [
            (v18 /*: any*/),
            {
                "kind": "InlineFragment",
                "selections": [
                    (v19 /*: any*/),
                    (v20 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "width",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "height",
                        "storageKey": null
                    },
                    (v21 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "video",
                        "storageKey": null
                    }
                ],
                "type": "GiphyMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v22 /*: any*/),
                "type": "TwitterMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v19 /*: any*/),
                    (v21 /*: any*/),
                    (v20 /*: any*/)
                ],
                "type": "YouTubeMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v22 /*: any*/),
                "type": "ExternalMedia",
                "abstractKey": null
            }
        ],
        "storageKey": null
    } as any), v24 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
    } as any), v25 = [
        (v7 /*: any*/),
        (v4 /*: any*/)
    ], v26 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "flagger",
        "plural": false,
        "selections": [
            (v4 /*: any*/),
            (v7 /*: any*/)
        ],
        "storageKey": null
    } as any), v27 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "additionalDetails",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "UserHistoryDrawerAllCommentsPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "count",
                                    "variableName": "count"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "cursor",
                                    "variableName": "cursor"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "UserHistoryDrawerAllComments_user"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v2 /*: any*/),
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Operation",
            "name": "UserHistoryDrawerAllCommentsPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        {
                            "alias": null,
                            "args": (v6 /*: any*/),
                            "concreteType": "CommentsConnection",
                            "kind": "LinkedField",
                            "name": "allComments",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v7 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Site",
                                                    "kind": "LinkedField",
                                                    "name": "site",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "author",
                                                    "plural": false,
                                                    "selections": [
                                                        (v7 /*: any*/),
                                                        (v5 /*: any*/),
                                                        (v4 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "UserStatus",
                                                            "kind": "LinkedField",
                                                            "name": "status",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "current",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "BanStatus",
                                                                    "kind": "LinkedField",
                                                                    "name": "ban",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v10 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Site",
                                                                            "kind": "LinkedField",
                                                                            "name": "sites",
                                                                            "plural": true,
                                                                            "selections": (v9 /*: any*/),
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "SuspensionStatus",
                                                                    "kind": "LinkedField",
                                                                    "name": "suspension",
                                                                    "plural": false,
                                                                    "selections": (v11 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "PremodStatus",
                                                                    "kind": "LinkedField",
                                                                    "name": "premod",
                                                                    "plural": false,
                                                                    "selections": (v11 /*: any*/),
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "role",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "premoderatedBecauseOfEmailAt",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "kind": "ClientExtension",
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "allCommentsRejected",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "commentsRejectedOnSites",
                                                                    "storageKey": null
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v12 /*: any*/),
                                                (v13 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "rating",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevision",
                                                    "kind": "LinkedField",
                                                    "name": "revision",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ActionCounts",
                                                            "kind": "LinkedField",
                                                            "name": "actionCounts",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "FlagActionCounts",
                                                                    "kind": "LinkedField",
                                                                    "name": "flag",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "FlagReasonActionCounts",
                                                                            "kind": "LinkedField",
                                                                            "name": "reasons",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_BANNED_WORD",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_SUSPECT_WORD",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_REPORTED_OFFENSIVE",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_REPORTED_ABUSIVE",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_REPORTED_SPAM",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_REPORTED_OTHER",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_TOXIC",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_SPAM",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_REPORTED_BIO",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_REPEAT_POST",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_RECENT_HISTORY",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_NEW_COMMENTER",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_LINKS",
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "IllegalActionCounts",
                                                                    "kind": "LinkedField",
                                                                    "name": "illegal",
                                                                    "plural": false,
                                                                    "selections": (v14 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ReactionActionCounts",
                                                                    "kind": "LinkedField",
                                                                    "name": "reaction",
                                                                    "plural": false,
                                                                    "selections": (v14 /*: any*/),
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "CommentRevisionMetadata",
                                                            "kind": "LinkedField",
                                                            "name": "metadata",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "CommentRevisionWordListMetadata",
                                                                    "kind": "LinkedField",
                                                                    "name": "wordList",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "WordlistMatch",
                                                                            "kind": "LinkedField",
                                                                            "name": "bannedWords",
                                                                            "plural": true,
                                                                            "selections": (v15 /*: any*/),
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "WordlistMatch",
                                                                            "kind": "LinkedField",
                                                                            "name": "suspectWords",
                                                                            "plural": true,
                                                                            "selections": (v15 /*: any*/),
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "timedOut",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "CommentRevisionPerspectiveMetadata",
                                                                    "kind": "LinkedField",
                                                                    "name": "perspective",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "score",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "CommentRevisionExternalModerationPhaseMetadata",
                                                                    "kind": "LinkedField",
                                                                    "name": "externalModeration",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        (v8 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "ExternalModerationPhaseResult",
                                                                            "kind": "LinkedField",
                                                                            "name": "result",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v16 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "tags",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "ExternalModerationPhaseAction",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "actions",
                                                                                    "plural": true,
                                                                                    "selections": [
                                                                                        (v17 /*: any*/)
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "analyzedAt",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v7 /*: any*/),
                                                        (v23 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Tag",
                                                    "kind": "LinkedField",
                                                    "name": "tags",
                                                    "plural": true,
                                                    "selections": [
                                                        (v24 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v16 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "EditInfo",
                                                    "kind": "LinkedField",
                                                    "name": "editing",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "edited",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Comment",
                                                    "kind": "LinkedField",
                                                    "name": "parent",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "author",
                                                            "plural": false,
                                                            "selections": (v25 /*: any*/),
                                                            "storageKey": null
                                                        },
                                                        (v7 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "canModerate",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Story",
                                                    "kind": "LinkedField",
                                                    "name": "story",
                                                    "plural": false,
                                                    "selections": [
                                                        (v7 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "StoryMetadata",
                                                            "kind": "LinkedField",
                                                            "name": "metadata",
                                                            "plural": false,
                                                            "selections": [
                                                                (v20 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "StorySettings",
                                                            "kind": "LinkedField",
                                                            "name": "settings",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "mode",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "isArchived",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "isArchiving",
                                                            "storageKey": null
                                                        },
                                                        (v19 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "permalink",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "deleted",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": [
                                                        {
                                                            "kind": "Literal",
                                                            "name": "first",
                                                            "value": 1
                                                        }
                                                    ],
                                                    "concreteType": "CommentModerationActionConnection",
                                                    "kind": "LinkedField",
                                                    "name": "statusHistory",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "CommentModerationActionEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "CommentModerationAction",
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "RejectionReason",
                                                                            "kind": "LinkedField",
                                                                            "name": "rejectionReason",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v24 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "legalGrounds",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "detailedExplanation",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "customReason",
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        (v7 /*: any*/),
                                                                        (v12 /*: any*/),
                                                                        (v16 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "User",
                                                                            "kind": "LinkedField",
                                                                            "name": "moderator",
                                                                            "plural": false,
                                                                            "selections": (v25 /*: any*/),
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": "statusHistory(first:1)"
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "FlagsConnection",
                                                    "kind": "LinkedField",
                                                    "name": "flags",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Flag",
                                                            "kind": "LinkedField",
                                                            "name": "nodes",
                                                            "plural": true,
                                                            "selections": [
                                                                (v26 /*: any*/),
                                                                (v17 /*: any*/),
                                                                (v27 /*: any*/),
                                                                (v7 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "FlagsConnection",
                                                    "kind": "LinkedField",
                                                    "name": "illegalContent",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Flag",
                                                            "kind": "LinkedField",
                                                            "name": "nodes",
                                                            "plural": true,
                                                            "selections": [
                                                                (v26 /*: any*/),
                                                                (v27 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "reportID",
                                                                    "storageKey": null
                                                                },
                                                                (v7 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevision",
                                                    "kind": "LinkedField",
                                                    "name": "revisionHistory",
                                                    "plural": true,
                                                    "selections": [
                                                        (v7 /*: any*/),
                                                        (v13 /*: any*/),
                                                        (v12 /*: any*/),
                                                        (v23 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "initialStatus",
                                                    "storageKey": null
                                                },
                                                (v18 /*: any*/),
                                                {
                                                    "kind": "ClientExtension",
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "viewerDidModerate",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "statusLiveUpdated",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "enteredLive",
                                                            "storageKey": null
                                                        }
                                                    ]
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v6 /*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "UserHistoryDrawer_allComments",
                            "kind": "LinkedHandle",
                            "name": "allComments"
                        },
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "5eb4e325bd80a7ea4b8fa7e84195ad1a",
            "metadata": {},
            "name": "UserHistoryDrawerAllCommentsPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '0ac604ae7cdcbaf3b6270927988e3bf7';
export default node;
